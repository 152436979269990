@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.cdnfonts.com/css/inter');
@import url('https://fonts.cdnfonts.com/css/nunito');
@import url('https://fonts.cdnfonts.com/css/playfair-display');
@import url('https://fonts.cdnfonts.com/css/poppins');
@import url("https://code.highcharts.com/css/highcharts.css");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Inter', sans-serif;
  font-style: normal;
}

button {
  cursor: pointer;
}

/* 
*Calendar styling
*/
.react-calendar {
  /* width: 350px; */
  max-width: 100%;
  background: white;
  line-height: 1.125em;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 16.3228px;
  text-align: center;
  letter-spacing: 0.466437px;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 42px;
  background: none;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 13.6535px;
  line-height: 32px;
  letter-spacing: 0.506417px;
  color: rgba(51, 51, 51, 0.9);
  flex: none;
  order: 1;
  flex-grow: 0;
}
.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}
.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 1em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__tile--now {
  background: #ffff76;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}
.react-calendar__tile--hasActive {
  background: #292929;
  color: white;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #DF7024;
}
.react-calendar__tile--active {
  width: 33.93px;
  height: 32.2px;
  background: #292929;
  border: 1.33268px solid #FFFFFF;
  border-radius: 50px;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #DF7024;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

/*
*HighCharts Styling
*/

.highcharts-data-table table {
  min-width: 310px;
  max-width: 800px;
  margin: 1em auto;
  font-family: 'Poppins', sans-serif;
  border-collapse: collapse;
  border: 1px solid #ebebeb;
  margin: 10px auto;
  text-align: center;
  width: 100%;
  max-width: 500px;
}

.highcharts-data-table caption {
  padding: 1em 0;
  font-size: 1.2em;
  color: #555;
}

.highcharts-data-table th {
  font-weight: 600;
  padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
  padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
  background: #f8f8f8;
}

.highcharts-data-table tr:hover {
  background: #f1f7ff;
}

.highcharts-yaxis .highcharts-axis-line {
  stroke-width: 2px;
}

/* Link the series colors to axis colors */
.highcharts-color-0 {
  fill: #7cb5ec;
  stroke: #7cb5ec;
}

.highcharts-axis.highcharts-color-0 .highcharts-axis-line {
  stroke: #7cb5ec;
}

.highcharts-axis.highcharts-color-0 text {
  fill: #7cb5ec;
}

.highcharts-color-1 {
  fill: #90ed7d;
  stroke: #90ed7d;
}

.highcharts-axis.highcharts-color-1 .highcharts-axis-line {
  stroke: #90ed7d;
}

.highcharts-axis.highcharts-color-1 text {
  fill: #90ed7d;
}

/* .highcharts-column-series .highcharts-point {
  width: 32.57px !important;
} */

.highcharts-column-series .highcharts-point-hover {
  stroke: #092C4C !important;
  fill: #092C4C !important;
  fill-opacity: 1 !important;
}

.active {
  @apply text-primary
}

.hide-scrollbar {
  /* overflow: scroll; */
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.card-container {
  @apply bg-white p-3.5 md:p-5 rounded-lg shadow;
}

.table-header {
  @apply whitespace-nowrap py-3 px-4 text-left font-[500] text-sm 2xl:text-base
}

.table-content {
  @apply py-3 px-4 whitespace-nowrap text-left text-sm 2xl:text-base
}

.popup {
  @apply flex items-center gap-2 hover:bg-[#ebeff7] active:bg-[#ebeff7] border-b
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

@media print {
  .no-print {
    display: none;
  }
  .print {
    display: block;
  }

  @page {
    margin: 20mm 0; /* Set your desired margin size */
  }
}

.sah__single-value--is-disabled, .sah__control--is-disabled {
  color: #4e4d4d !important;
  background: #eae9e9 !important;
}

.print-table-grid {
  @apply border border-[#5F6D7EDE] p-2 text-black capitalize
}

.otp-pin {
  border-radius: 10px;
  margin: 0px 16px;
  color: #000;
  font-size: 18px;
  width: 3rem !important;
  height: 3rem !important;
  border: 1px solid #ccc !important;
  background: #D9D9D999;
}

@media (max-width: 768px)   {

  .otp-pin {
    margin: 0px 8px;
    font-size: 16px;
    width: 2.5rem !important;
    height: 2.5rem !important;
  }
}